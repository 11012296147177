*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.upload-selector-btn {
  background: white;
  width: 36px;
  height: 36px;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  border: none;
  cursor: pointer;
}

.upload-selector-btn:hover {
  opacity: 0.5;
}

.upload-selector-menu {
  background: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  width: 320px;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  max-height: 50vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.upload-selector-menu ul {
  list-style: none;
  margin: 0;
  padding: 24px;
}

.upload-selector-menu.is-opened {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  pointer-events: auto;
}

.upload-selector-menu li + li {
  margin-top: 12px;
}

.upload-selector-menu a {
  text-decoration: none;
  color: #242424;
}

.upload-selector-menu .is-active a {
  font-weight: bold;
}

.upload-selector-menu a:hover {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
